import React, { useEffect } from "react";
import Layout from "../../components/layout/Layout";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import DesktopMacIcon from "@mui/icons-material/DesktopMac";
import ListIcon from "@mui/icons-material/List";
import "./dashboard.css";
import { useDispatch, useSelector } from "react-redux";
import AreaChart from "../../components/charts/AreaChart";
import DonutChart from "../../components/charts/DonutChart";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { Label } from "@mui/icons-material";
import { Grid, List, ListItem, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { styled, alpha } from "@mui/material/styles";
import { getPlaylist } from "../../Redux/Actions/playlistAction";
import { getScreens } from "../../Redux/Actions/screenAction";
import { getContent } from "../../Redux/Actions/contentAction";
import { login } from "../../Redux/Actions/userAction";
const CustomListItem = styled(ListItem)(({ theme }) => ({
  padding: 0,
}));

function Dashboard({ user, socketRef }) {
  const theme = createTheme({
    status: {
      danger: "#e53e3e",
    },
    palette: {
      primary: {
        main: "#0971f1",
        darker: "#053e85",
      },
      neutral: {
        main: "#64748B",
        contrastText: "#fff",
      },
      url: {
        main: "#FD2254",
      },
      image: {
        main: "#FF6A00",
      },
      video: {
        main: "#551fff",
      },
    },
  });
  const dispatch = useDispatch();
  const { content, isContentLoading } = useSelector((state) => state.Content);
  const { playlist, isPlaylistLoading } = useSelector(
    (state) => state.Playlist
  );
  const { screens, screenLoading } = useSelector((state) => state.Screen);
  const { users } = useSelector((state) => state.User);
  useEffect(() => {
    socketRef.current.emit("leaving--connection");
    dispatch(getContent(user?._id));
    dispatch(getPlaylist(user?._id));
    dispatch(getScreens(user?._id));
  }, []);

  return (
    <>
      <Layout title={"Dashboard"} user={user}>
        <ThemeProvider theme={theme}>
          <Grid container px={2}>
            {/* <Grid lg={9} md={9} className="dashboard-container flex"> */}
            <Grid lg={8.5} md={8.5} pr={2}>
              <Grid
                container
                sx={{
                  padding: "30px 13px 0 15px",
                  background: "#FFFFFF",
                  boxShadow: "0px 18px 32px rgba(208, 210, 218, 0.15)",
                  borderRadius: "20px",
                }}
              >
                <Grid item lg={3} pr={3}>
                  <Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    <TextSnippetIcon
                      sx={{
                        background: "#FFF2E9",
                        padding: "15px",
                        color: "#FF6A00",
                        borderRadius: "16px",
                      }}
                    />
                    <Box pl={1}>
                      <Typography
                        sx={{
                          color: "#92959E",
                          fontSize: "13px",
                          fontWeight: 700,
                        }}
                      >
                        Content
                      </Typography>
                      {isContentLoading ? (
                        <Box className="minLoader"></Box>
                      ) : (
                        <Typography
                          sx={{
                            color: "#92959E",
                            fontSize: "14px",
                          }}
                        >
                          {content.length}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      background: "#FFF2E9",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "16px",
                      mt: 2,
                      padding: "10px 0",
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#92959E",
                        fontSize: "14px",
                      }}
                    >
                      <span style={{ color: "#FF6A00", paddingRight: "5px" }}>
                        55%
                      </span>
                      then last week
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={3} pr={3}>
                  <Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    <DesktopMacIcon
                      sx={{
                        background: "#EDE8FF",
                        padding: "15px",
                        color: "#551fff",
                        borderRadius: "16px",
                      }}
                    />
                    <Box pl={1}>
                      <Typography
                        sx={{
                          color: "#92959E",
                          fontSize: "13px",
                          fontWeight: 700,
                        }}
                      >
                        Add Screens
                      </Typography>
                      {screenLoading ? (
                        <Box className="minLoader"></Box>
                      ) : (
                        <Typography
                          sx={{
                            color: "#92959E",
                            fontSize: "14px",
                          }}
                        >
                          {screens.length}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      background: "#EDE8FF",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "16px",
                      mt: 2,
                      padding: "10px 0",
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#92959E",
                        fontSize: "14px",
                      }}
                    >
                      <span style={{ color: "#551fff", paddingRight: "5px" }}>
                        55%
                      </span>
                      then last week
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={3} pr={3}>
                  <Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    <ListIcon
                      sx={{
                        background: "#EAF9FF",
                        padding: "15px",
                        color: "#00B7FE",
                        borderRadius: "16px",
                      }}
                    />
                    <Box pl={1}>
                      <Typography
                        sx={{
                          color: "#92959E",
                          fontSize: "13px",
                          fontWeight: 700,
                        }}
                      >
                        Playlist
                      </Typography>
                      {isPlaylistLoading ? (
                        <Box className={"minLoader"}></Box>
                      ) : (
                        <Typography
                          sx={{
                            color: "#92959E",
                            fontSize: "14px",
                          }}
                        >
                          {playlist.length}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      background: "#EAF9FF",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "16px",
                      mt: 2,
                      padding: "10px 0",
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#92959E",
                        fontSize: "14px",
                      }}
                    >
                      <span style={{ color: "#00B7FE", paddingRight: "5px" }}>
                        55%
                      </span>
                      then last week
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={3} pr={1}>
                  <Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    <PersonAddIcon
                      sx={{
                        background: "#FFEBEF",
                        padding: "15px",
                        color: "#FD2254",
                        borderRadius: "16px",
                      }}
                    />
                    <Box pl={1}>
                      <Typography
                        sx={{
                          color: "#92959E",
                          fontSize: "13px",
                          fontWeight: 700,
                        }}
                      >
                        Followers
                      </Typography>
                      <Typography
                        sx={{
                          color: "#92959E",
                          fontSize: "14px",
                        }}
                      >
                        {users.length}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      background: "#FFEBEF",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "16px",
                      mt: 2,
                      padding: "10px 0",
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#92959E",
                        fontSize: "14px",
                      }}
                    >
                      <span style={{ color: "#FD2254", paddingRight: "5px" }}>
                        55%
                      </span>
                      then last week
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              <Box
                sx={{
                  marginTop: "20px",
                  marginBottom: "20px",
                  background: "#FFFFFF",
                  // boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.2)',
                  borderRadius: "20px",
                  padding: "20px",
                  //height:'100%',
                  width: {
                    xl: "1100",
                    lg: "1100",
                    md: "1100",
                    sm: "95%",
                    xs: "95%",
                  },
                  display: {
                    xl: "block",
                    lg: "block",
                    md: "block",
                    sm: "block",
                    xs: "none",
                  },
                }}
              >
                <AreaChart />
              </Box>
            </Grid>
            <Grid lg={3.5} md={3.5} xs={11.5}>
              <Box
                sx={{
                  background: "#FFFFFF",
                  borderRadius: "20px",
                  padding: "20px 10px",
                  display: {
                    xl: "flex",
                    lg: "flex",
                    md: "flex",
                    sm: "flex",
                    xs: "none",
                  },
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <DonutChart
                  series={[44, 55, 13, 33, 55, 33, 22]}
                  labels={["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]}
                />
                <List
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    padding: 0,
                    fontSize: "12px",
                  }}
                >
                  <CustomListItem>
                    <span
                      style={{
                        background: "#0068b9",
                        width: "7px",
                        height: "7px",
                        // borderRadius: '50%',
                        marginRight: "5px",
                      }}
                    ></span>
                    <span>Mon</span>
                  </CustomListItem>
                  <CustomListItem>
                    <span
                      style={{
                        background: "#00e396",
                        width: "7px",
                        height: "7px",
                        // borderRadius: '50%',
                        marginRight: "5px",
                      }}
                    ></span>
                    <span>Tue</span>
                  </CustomListItem>
                  <CustomListItem>
                    <span
                      style={{
                        background: "#feb019",
                        width: "7px",
                        height: "7px",
                        // borderRadius: '50%',
                        marginRight: "5px",
                      }}
                    ></span>
                    Wed
                  </CustomListItem>
                  <CustomListItem>
                    <span
                      style={{
                        background: "#bb2e45",
                        width: "7px",
                        height: "7px",
                        // borderRadius: '50%',
                        marginRight: "5px",
                      }}
                    ></span>

                    <span>Thu</span>
                  </CustomListItem>
                  <CustomListItem>
                    <span
                      style={{
                        background: "#775dd0",
                        width: "7px",
                        height: "7px",
                        // borderRadius: '50%',
                        marginRight: "5px",
                      }}
                    ></span>

                    <span>Fri</span>
                  </CustomListItem>
                  <CustomListItem>
                    <span
                      style={{
                        background: "#008ffb",
                        width: "7px",
                        height: "7px",
                        // borderRadius: '50%',
                        marginRight: "5px",
                      }}
                    ></span>
                    Sat
                    <span></span>
                  </CustomListItem>
                  <CustomListItem>
                    <span
                      style={{
                        background: "#00e396",
                        width: "7px",
                        height: "7px",
                        // borderRadius: '50%',
                        marginRight: "5px",
                      }}
                    ></span>

                    <span>Sun</span>
                  </CustomListItem>
                </List>
              </Box>
              <Box
                sx={{
                  background: "#FFFFFF",
                  borderRadius: "20px",
                  padding: "25px 20px 65px 20px",
                  marginTop: "20px",
                  // width:"100%"
                }}
              >
                <div className="progress-bar-title">
                  <Typography
                    sx={{
                      color: "#15192C",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    Content Type
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color: "#92959E",
                      mb: 2,
                    }}
                  >
                    Last Campaign Performance
                  </Typography>
                </div>
                <div className="progress">
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 400,
                      }}
                    >
                      Url
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 400,
                      }}
                    >
                      {isContentLoading ? (
                        <Box className="minLoader"></Box>
                      ) : (
                        [...content.filter((c) => c.type === "url")].length
                      )}
                    </Typography>
                  </Box>
                  <LinearProgress
                    variant={"determinate"}
                    value={[...content.filter((c) => c.type === "url")].length}
                    color={"url"}
                  />
                  <br />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 400,
                      }}
                    >
                      Image
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 400,
                      }}
                    >
                      {isContentLoading ? (
                        <Box className="minLoader"></Box>
                      ) : (
                        [...content.filter((c) => c.type === "image")].length
                      )}
                    </Typography>
                  </Box>
                  <LinearProgress
                    variant={"determinate"}
                    value={
                      [...content.filter((c) => c.type === "image")].length
                    }
                    color={"image"}
                  />
                  <br />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 400,
                      }}
                    >
                      Video
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 400,
                      }}
                    >
                      {isContentLoading ? (
                        <Box className="minLoader"></Box>
                      ) : (
                        [...content.filter((c) => c.type === "video")].length
                      )}
                    </Typography>
                  </Box>
                  <LinearProgress
                    variant={"determinate"}
                    value={
                      [...content.filter((c) => c.type === "video")].length
                    }
                    color={"video"}
                  />
                  <br />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 400,
                      }}
                    >
                      Pdf
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 400,
                      }}
                    >
                      {isContentLoading ? (
                        <Box className="minLoader"></Box>
                      ) : (
                        [...content.filter((c) => c.type === "application")]
                          .length
                      )}
                    </Typography>
                  </Box>
                  <LinearProgress
                    variant={"determinate"}
                    value={
                      [...content.filter((c) => c.type === "application")]
                        .length
                    }
                    color={"success"}
                  />
                </div>
              </Box>
            </Grid>
            {/* </Grid> */}
          </Grid>
        </ThemeProvider>
      </Layout>
    </>
  );
}

export default Dashboard;
