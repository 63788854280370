import axios from 'axios';
export const baseApi=`${process.env.REACT_APP_URL_API}`;
export const imgUrl=`${process.env.REACT_APP_URL_IMAGE}`;
// export const baseApi='http://localhost:5000/api';
// export const imgUrl='http://localhost:5000';

export const api = axios.create({
    baseURL:baseApi,
    withCredentials:true,
    // headers:{
    //     'Content-type':['multipart/form-data','application/json'],
    //     Accept:'application/json'
    // }
})